import { ReactNode } from "react";
import { cn } from "~/lib/utils";

interface FormFieldProps {
  label?: string;
  error?: string | string[];
  children: ReactNode;
  className?: string;
}

export default function FormField({
  label,
  error,
  children,
  className,
}: FormFieldProps) {
  return (
    <div className={cn("flex flex-col w-full gap-1", className)}>
      {label && <label className="block">{label}</label>}
      {children}
      {error && <span className="text-sm text-red-500">{error}</span>}
    </div>
  );
}
