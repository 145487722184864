interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export default function Input({ ...props }: InputProps) {
  return (
    <input
      className="w-full p-2 text-medium border border-border rounded-md bg-componentBackground text-text active:bg-selected"
      {...props}
    />
  );
}
